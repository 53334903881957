<template>
  <div class="login">
    <img
      src="@/assets/images/bg_login.png"
      alt=""
      class="bg-login"
      :style="{ height: `${clientHeight}px` }"
    />
    <div class="meta">
      <img src="@/assets/images/logo.png" alt="" />
      <p>慧言</p>
    </div>
    <div class="info phone">
      <van-field
        v-model="phone"
        placeholder="请输入手机号"
        @blur="otherLoginShow = true"
        @focus="otherLoginShow = false"
        type="number"
        maxlength="11"
      />
    </div>
    <div class="info yanzheng">
      <div class="yanzheng-flex">
        <van-field
          id="code"
          v-model="yanzheng"
          placeholder="请输入验证码"
          type="number"
          inputmode="numeric"
          autocomplete="one-time-code"
          maxlength="6"
          @blur="otherLoginShow = true"
          @focus="otherLoginShow = false"
        />
        <van-button
          v-if="showtime === null"
          @click.prevent="getCode($event)"
          :disabled="disabled"
          class="getCode"
          >获取验证码</van-button
        >
        <van-button disabled v-else class="time">{{ showtime }}</van-button>
      </div>
    </div>
    <van-button class="btn" @click.native="loginIn" 
      >登录</van-button
    >

    <div class="login-ways" v-if="runEnv!='ANDROID'">
      <p>第三方登录</p>
      <div class="way-item" @click="wxLogin" >
        <div class="img-wraper">
          <img src="@/assets/images/icon_wechat.png" alt="" />
        </div>
        <span>微信登录</span>
      </div>
      <div class="way-item" @click="appleLogin" v-if="runEnv == 'IOS'">
        <div class="img-wraper">
          <img src="@/assets/images/icon_apple.png" alt="" />
        </div>

        <span>苹果登录</span>
      </div>
    </div>
    <div class="agree">
      <span
        >登录即代表同意<span style="color: #3b5bff" @click="nav('/privacy')"
          >《隐私协议》</span
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      phone: "",
      yanzheng: "",
      showtime: null,
      clickTimes: 1,
      disabled: true,
      showlogin: false,
      // 控制器
      ac: null,
      autoTimer: null,
      runEnv: "",
      clientHeight: 0,
      otherLoginShow: true,
    };
  },
  created() {
    document.body.style.background = "white";
    this.runEnv = this.deviceEnvir();
    if(localStorage.getItem('token')){
      this.$router.replace("/scene")
      return
    }
    
   

    window.wxLoginCallback = this.wxLoginCallback;
    window.appleLoginCallback = this.appleLoginCallback;
  },
  mounted() {
    this.clientHeight = document.body.clientHeight;
    document.body.addEventListener("focusin", (e) => {
      var timer = setInterval(() => {
        document.body.scrollTop = document.body.scrollHeight;
        clearInterval(timer);
      }, 300);
    });
  },
  watch: {
    phone(cur) {
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      this.disabled = !reg.test(cur);
    },
  },
  methods: {
async  checkInvite() {

     const res=  await this.$api.login.checkInvite(localStorage.getItem("id"))
     if(res.code==200){
      if(!res.data){
        this.$router.replace({ path: "/inviteIs" })
      }else{
        this.$router.replace({ path: "/scene" })
      }
      
     }
  
    },
    autoCode() {
      console.log("OTPCredential" in window);
      if ("OTPCredential" in window) {
        console.log(navigator.credentials);
        // DOMContentLoaded 事件会等待 DOM树准备好
        this.ac = new AbortController();
        // 用户可以自己输入验证码并且提交，使用 AbortController 终止 Web OTP API 的自动填充
        // AbortController 还可以终止 fetch 的请求
        // 监听终止事件 ac.signal.addEventListener('abort', function(){});

        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: this.ac.signal,
          })
          .then((otp) => {
            console.log(" navigator.credentials otp");
            alert(JSON.stringify(otp));
            this.yanzheng = otp.code;
          })
          .catch((err) => {
            alert(`err`, err);
            console.log(err);
          });
      } else {
        // this.$toast('非https不支持验证码自动填充')
      }
    },
    // 获取验证码
    getCode() {
      // 点击太过频繁
      // if (event.target.disabled) {
      //   return false;
      // }
      this.countDown(60);
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.phone)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        this.countDown(0);
        return false;
      } else {
        // this.disabled = true;
        // return
        this.$api.login
          .getCode({
            mobile: this.phone,
          })
          .then((res) => {
            console.log("获取验证码", res);
            // this.disabled = false;
            this.clickTimes += 1;
            if (res.code === 200) {
              // this.countDown(60);

              this.yanzheng = res.data.code;
            } else {
              this.$toast(res.errmsg);
            }
            // if (res.data.code === 200) {
            //   this.countDown(60);
            //   this.yanzheng = res.data.checkCode;
            // }
          });
      }
    },
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后再获取`;
    },
    // 倒计时 60秒 不需要很精准
    countDown(times) {
      if (times) this.showtime = "发送中";
      const self = this;
      // 时间间隔 1秒
      const interval = 1000;
      let count = 0;
      self.timeCounter = setTimeout(countDownStart, interval);
      // 定时器中的方法
      function countDownStart() {
        if (self.timeCounter == null) {
          return false;
        }
        count++;
        self.countDownText(times - count + 1);
        if (count > times) {
          self.showlogin = false;
          clearTimeout(self.timeCounter);
          self.showtime = null;
        } else {
          self.timeCounter = setTimeout(countDownStart, interval);
        }
      }
    },
    goBack() {
      // let url = decodeURIComponent(this.$route.query.redirect);
      // this.$router.replace({ path: url });
      this.$router.go(-1);
    },
    deviceEnvir() {
      var u = window.navigator.userAgent;

      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      var isIOS =
        !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) ||
        window.navigator.userAgent.indexOf("Mac OS X") != -1; // 判断是否是 ios终端
      var isWeiChat =
        u.toLowerCase().match(/MicroMessenger/i) == "micromessenger"; // 判断是否是微信环境
      return isWeiChat
        ? "WECHAT"
        : isIOS
        ? "IOS"
        : isAndroid
        ? "ANDROID"
        : "H5";
    },
    //微信登录
    wxLogin() {
      if (this.deviceEnvir() == "WECHAT") {
        const url = `${location.href.split("/login")[0]}/bindPhone`;
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WECHAT_APP_ID}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`;
      } else {
        this.appWxlogin();
      }
    },
    appWxlogin() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "登录中",
      });
      let paramsObj = {
        appid: process.env.VUE_APP_APP_ID,
        state: "1",
      };
      switch (this.deviceEnvir()) {
        case "IOS":
          try {
            window.webkit.messageHandlers.wxLogin.postMessage(paramsObj);
          } catch (error) {
            this.$toast("暂不支持登录");
          }
          break;
        case "ANDROID":
          paramsObj = JSON.stringify(paramsObj);
          window.revisionInterface.wxLogin(paramsObj);
          break;
      }
    },
    wxLoginCallback(params) {
      let paramsObj = this.deviceEnvir() == "IOS" ? JSON.parse(params) : params;
      localStorage.setItem("callbackWay", "wx");
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "登录中",
      });
      this.$api.login
        .appleWXLogin({
          code: paramsObj.code
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.wechatOpenId &&
              localStorage.setItem("openId", res.data.wechatOpenId);
            this.saveUserInfo(res);
         
            res.data.token&& localStorage.setItem("rtoken",res.data.token)
            if (res.data.bind) {
              this.$toast({
                message: "登录成功",
                duration: 1500,
                onClose: () => {
                  if (res.data.codeBind) {
                    this.$toast({
                      message: "登录成功",
                      duration: 1500,
                      onClose: () => {
                        localStorage.setItem("token",localStorage.getItem('rtoken'))
                        // 把token存入本地
                        let paramsObj = JSON.stringify({
                          token: res.data.token,
                        });
                        switch (this.deviceEnvir()) {
                          case "IOS":
                            try {
                              window.webkit.messageHandlers.saveToken.postMessage(
                                paramsObj
                              );
                            } catch (error) {}
                            break;
                          case "ANDROID":
                            try {
                              window.revisionInterface.saveToken(paramsObj);
                            } catch (error) {}
                        }
                        this.$router.replace({ path: "/scene" });
                      },
                    });
                  } else {
                    this.$router.push({
                      path: "/inviteIs",
                    });
                  }
                },
              });
            } else {
              // this.$router.push({
              //   path: "/inviteIs?redirect=bindPhone",
              // });
              this.$router.push(`/bindPhone?openId=${res.data.wechatOpenId}`);
            }
          }
          this.$toast.clear();
        });
    },
    appleLogin() {
      try {
        window.webkit.messageHandlers.appleLogin.postMessage("1");
      } catch (error) {
        this.$toast("暂不支持登录");
      }
    },
    appleLoginCallback(params) {
      localStorage.clear();
      localStorage.setItem("callbackWay", "apple");
      const paramsObj = JSON.parse(params);
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "登录中",
      });
      this.$api.login
        .appleLogin({
          openId: paramsObj.openId,
          authorizationCode: paramsObj.authorizationCode,
          identityToken: paramsObj.identityToken,
          realUserStatus: paramsObj.realUserStatus,
        })
        .then((res) => {
          
          res.data.appleOpenId &&
            localStorage.setItem("openId", res.data.appleOpenId);
          this.saveUserInfo(res);
          res.data.token&& localStorage.setItem("rtoken",res.data.token)
          if (res.data.bind) {
            this.$toast({
              message: "登录成功",
              duration: 1500,
              onClose: () => {
                if (res.data.codeBind) {
                  this.$toast({
                    message: "登录成功",
                    duration: 1500,
                    onClose: () => {
                      localStorage.setItem("token",localStorage.getItem('rtoken'))
                      // 把token存入本地
                      let paramsObj = JSON.stringify({
                        token: res.data.token,
                      });
                      switch (this.deviceEnvir()) {
                        case "IOS":
                          try {
                            window.webkit.messageHandlers.saveToken.postMessage(
                              paramsObj
                            );
                          } catch (error) {}
                          break;
                        case "ANDROID":
                          try {
                            window.revisionInterface.saveToken(paramsObj);
                          } catch (error) {}
                      }
                      this.$router.replace({ path: "/scene" });
                    },
                  });
                } else {
                  this.$router.push({
                    path: "/inviteIs",
                  });
                }
              },
            });
          } else {
            this.$router.push(`/bindPhone?openId=${res.data.appleOpenId}`);
          }
          this.$toast.clear();
        });
    },
    saveUserInfo(res) {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("id", res.data.id);
      let userInfo = {
        name: res.data.name,
        mobile: res.data.mobile,
      };
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    // 确定登录 优化版本
    loginIn() {

      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      // 电话号码正确就登录
      if (!reg.test(this.phone)) {
        this.$toast("电话号码有误");
        return false;
      } else {
        // this.showlogin = true; // 点击登录之后就变灰色
        this.$toast.loading({
          message: "登录中...",
          forbidClick: true,
        });
        this.$api.login
          .codeLogin({
            mobile: this.phone,
            code: this.yanzheng,
          })
          .then((res) => {
            console.log("登录", res);
            this.$toast.clear();
            if (res.code === 200) {
              // this.showlogin = false;
              // localStorage.setItem("token", res.data.token);
              // localStorage.setItem("id", res.data.id);
              let userInfo = {
                name: res.data.name,
                mobile: res.data.mobile,
              };
              // console.log("userInfo", userInfo);
              // 将客户信息存入Vuex
              // this.$store.commit("user/SAVE_USERINFO", userInfo);
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              this.ac && this.ac.abort();
              this.$toast({
                message: "登录成功",
                duration: 1500,
                onClose: () => {
                  // 跳转页面
                  this.yanzheng = "";
                  this.phone = "";
                  this.countDown(0);
                  // this.$router.replace({ path: "/index" });
                  console.log(this.$route.query);
                  localStorage.setItem("token", res.data.token);
                  localStorage.setItem("id", res.data.id);
                  if (!res.data.codeBind) {
                    this.$router.replace({
                      path: "/inviteIs?redirect=scene",
                    });
                  } else {
                    this.$router.replace({ path: "/scene" });
                  }
                },
              });
            } else {
              // this.showlogin = false;
              this.$toast(res.errmsg);
            }
          });
      }
    },
    nav(path) {
      console.log(path);
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less" scoped>
.agree {
  color: #d7d7d7;
  margin-top: 0.18rem;
  display: flex;
  font-size: 0.3rem;
  align-items: center;
  padding: 0 0.6rem 0.1rem;
  justify-content: center;
  & > span {
    margin-left: 0.2rem;
  }
}
.login-ways {
  // padding-bottom: 0.42rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.58rem;
  p {
    font-size: 0.28rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #aeaeae;
    margin-bottom: 0.48rem;
  }
  .way-item {
    width: 6.72rem;
    height: 1rem;
    border-radius: 0.2rem;
    border: 2px solid #e1e1e3;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-of-type(2) {
      span {
        color: #7e7e7e;
      }
    }
    .img-wraper {
      width: 0.44rem;
      height: 0.44rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    // img {
    //   width: 0.44rem;
    //   height: 0.44rem;
    //   }
    span {
      margin-left: 0.12rem;
      font-size: 0.36rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #24be48;
    }

    &:nth-of-type(1) {
      margin-bottom: 0.36rem;
    }
  }
}
.bg-login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}
.login {
  position: relative;
  height: 100vh;

  // background: url("~assets/images/bg_login.png") ;
  // background-size: 100% 100%;
  .meta {
    margin-bottom: 1.2rem;
    padding-top: 0.4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.64rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #294bf9;
    img {
      width: 2.44rem;
      height: 2.44rem;
    }
    p {
      margin-top: -0.1rem;
    }
  }

  .info {
    padding: 0 0.4rem /* 30/50 */;
    ::v-deep.van-field {
      .van-field__body {
        height: 100%;
      }
      height: 1rem;
      background: #f2f3f7;
      border-radius: 0.12rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #aeaeae;
      padding: 0.28rem 0.2rem;
      box-sizing: border-box;
      input {
        background: transparent;
      }
    }
  }
  .yanzheng {
    margin-top: 0.6rem;
  }
  .yanzheng-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.32rem /* 16/50 */;
    .van-field {
      width: 4.48rem;
      height: 1rem;
      background: #f2f3f7;
      border-radius: 0.1rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #aeaeae;
    }
  }
  .getCode {
    width: 2.08rem /* 104/50 */;
    height: 1rem /* 45/50 */;
    background: #f2f3f7;
    border-radius: 0.12rem;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.32rem /* 16/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #294bf9 !important;
  }
  .van-button--disabled {
    opacity: 1;
    color: #aeaeae !important;
  }
  .time {
    width: 2.08rem /* 104/50 */;
    height: 1rem /* 45/50 */;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-sizing: border-box;
    background: #f2f3f7;
    font-size: 0.28rem;
    color: #294bf9;
    border-radius: 0.1rem;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 0.4rem;
  }

  /deep/ .van-button--normal {
    padding: 0;
  }
  .btn {
    margin: 1rem 0.4rem 0;
    width: 6.72rem;
    height: 1rem /* 48/50 */;
    background: linear-gradient(360deg, #5b47f5 0%, #486efa 100%);
    border-radius: 5px;
    font-size: 0.4rem /* 18/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0.2rem;
  }
  .extra {
    margin: 0 0.6rem /* 30/50 */ 0.4rem /* 20/50 */;
    width: 6.3rem /* 315/50 */;
    display: flex;
    justify-content: space-between;
    font-size: 0.24rem /* 12/50 */;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4273f8;
    .unable {
      color: #aeaeae;
    }
  }
  .more-text {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 0.24rem /* 12/50 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: #aeaeae;
    margin-bottom: 0.4rem /* 20/50 */;
  }
  .more-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.32rem;
    .apple {
      width: 0.42rem /* 21/50 */;
      height: 0.5rem /* 25/50 */;
      margin-right: 2.08rem /* 104/50 */;
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .weixin {
      width: 1rem /* 28/50 */;
      height: 1rem /* 22/50 */;
      margin-bottom: 0.2rem /* 20/50 */;
    }
  }
}
.backbox {
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.12rem /* 6/50 */ 0.32rem /* 16/50 */;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem /* 14/50 */;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: 999;
  .back {
    top: 0.12rem /* 6/50 */;
    left: 0.32rem /* 16/50 */;
    color: #000;
    width: 0.64rem /* 32/50 */;
    text-align: center;
    height: 0.64rem /* 32/50 */;
    line-height: 0.64rem /* 32/50 */;
    border-radius: 50%;
    // background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
  i {
    opacity: 0;
  }
  &.backFixed {
    background-color: #fff;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    .back {
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      background-color: #fff;
      color: #ccc;
    }
    i {
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      opacity: 1;
      color: #c83f3fb8;
    }
  }
}
//第三方
.tripartite {
  position: absolute;
  bottom: 0.78rem;
  left: 50%;
  transform: translateX(-50%);
  color: #b1b8cb;

  .tripartite-item {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.12rem;
    }
    span {
      font-size: 0.32rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #7e7e7e;
    }
  }
}
</style>
